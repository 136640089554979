<template>
  <v-dialog
    v-model="open"
    scrollable
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="color3 color3Text--text"
        x-small text class="xsmall ma-0"
        v-on="on"
      >
        Is this you?{{view && view.vblx ? ` - ${profileStatus} ${player && player.users ? player.users.length : 0}` : ''}}
      </v-btn>
    </template>
    <v-card v-if="userStatus <= 0">
      <v-card-text v-if="userStatus === 0" class="pt-3">
        <div class="text-h3 text-center">
          You've been blocked!
        </div>
        <div>
          <v-img src="https://vblimages.blob.core.windows.net/images/Blocking2.gif" contain></v-img>
        </div>
        <v-alert type="error" :value="true" text prominent>
          You must be logged in to edit your player profile
        </v-alert>
      </v-card-text>
      <v-card-text v-if="userStatus === -1" class="pt-3">
        <div class="title mb-2 text-center">
          Verify it's you
        </div>
        <div class="subheading mb-2">
          {{user.firstName}}, before you can claim a profile, you must have a verified email address or mobile number on file.
        </div>
        <contact-add
          ref="addContact"
        ></contact-add>
      </v-card-text>
    </v-card>
    <v-card v-else>
      <v-toolbar color="color1 color1Text--text">
        <v-toolbar-title>Claim This Profile</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="color2 color2Text--text" small fab @click.stop="open = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <loading-bar :value="loading"></loading-bar>
      <v-card-text v-if="profileStatus === 'loading'" class="text-center">
        <v-progress-circular indeterminate color="color3" class="ma-13"></v-progress-circular>
      </v-card-text>
      <v-card-text v-else-if="profileStatus === 'unclaimed' || (view && view.vbl)">
        <v-text-field
          label="User Id"
          color="color3"
          v-model="id"
          v-if="user.vbl"
        ></v-text-field>
        <div v-else class="title">
          What is your relationship to {{player ? player.firstName : ''}}?
        </div>
        <v-select
          label="Relationship to player"
          color="color3"
          item-color="color3"
          :items="[{text: 'I am the player', value: 'Self'}, 'Parent', 'Coach', 'Club Director']"
          v-model="relation"
        ></v-select>
        <v-alert :value="!!relation" text type="error" prominent border="top" transition="scale-transition">
          For privacy reason, all non-public information will be deleted from {{player ? player.firstName : ''}}'s' profile
          and <b>you will need to re-enter the information after you complete this claim</b>. Please check
          the box below acknowledging you have fully read this.
        </v-alert>
        <v-expand-transition>
          <v-checkbox
            v-if="!!relation"
            label="I understand continuing will remove data from the profile and I will re-enter and maintain that data going forward"
            v-model="agreed"
            color="success"
          ></v-checkbox>
        </v-expand-transition>
      </v-card-text>
      <v-card-text v-else-if="profileStatus === 'challenge'">
        <two-factor-auth :challenges="claimStatus.challenges" ref="twoFactor" @passed="claimCheck"></two-factor-auth>
      </v-card-text>
      <v-card-text v-else>
        <v-alert type="error" :value="true" text prominent>
          Sorry this profile has already been claimed.
        </v-alert>
        <div>
          If you have more than 1 user account, please make sure you are logged into the correct account.
          If you feel this is a mistake or need any help please don't hesitate to contact us at <a href="mailto:ProfileHelp@volleyballlife.com">ProfileHelp@Volleyballlife.com</a>
        </div>
        <div class="text-h3 text-center">
          You've been blocked!
        </div>
        <div>
          <v-img src="https://vblimages.blob.core.windows.net/images/Blocking2.gif" contain></v-img>
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-fab-transition>
          <v-btn
            v-if="(userStatus > 0 && profileStatus === 'unclaimed') || (view && view.vbl)"
            :key="`key_${valid}`"
            color="success"
            fab small
            :disabled="!valid"
            :loading="loading"
            @click.stop="makeClaim"
          >
            <v-icon>fas fa-save</v-icon>
          </v-btn>
        </v-fab-transition>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import ContactAdd from '@/components/Account/ContactAdd'
const TwoFactorAuth = () => import('@/components/Account/TwoFactorAuth.vue')

export default {
  props: ['player', 'playerProfileId', 'view'],
  data () {
    return {
      open: false,
      loading: false,
      relation: null,
      agreed: false,
      claimStatus: false
    }
  },
  computed: {
    ...mapGetters(['user']),
    valid () {
      return this.agreed && this.relation
    },
    profileStatus () {
      if (!this.claimStatus) return 'loading'
      return this.claimStatus.canClaim ? this.claimStatus.challenges.length > 0 ? 'challenge' : 'unclaimed' : 'claimed'
    },
    userStatus () {
      // return -1
      if (!this.user) return 0 // 'No User'
      if (!this.user.hasVerifiedContact) return -1 // 'Need Verification'
      return 13
    },
    dto () {
      return {
        id: this.playerProfileId,
        userId: this.user.vbl ? this.id : this.user.id,
        relation: this.relation,
        request: false,
        requestNote: null
      }
    }
  },
  methods: {
    fetchUsers () {
      this.loading = true
      this.$VBL.player.users.get(this.playerProfileId)
        .then(r => {
          this.users = r.data
        })
        .catch(e => { console.log(e.response) })
        .finally(() => {
          this.loading = false
        })
    },
    claimCheck () {
      this.loading = true
      this.$VBL.player.users.claimCheck(this.playerProfileId)
        .then(r => {
          this.claimStatus = r.data
        })
        .catch(e => { console.log(e.response) })
        .finally(() => {
          this.loading = false
        })
    },
    makeClaim () {
      this.loading = true
      this.$VBL.player.users.makeClaim(this.dto)
        .then(r => {
          this.open = false
          this.$emit('just-claimed')
        })
        .catch(e => console.log(e.response))
        .finally(f => {
          this.loading = false
        })
    },
    reset () {
      this.relation = null
      this.agreed = null
      this.claimStatus = false
    }
  },
  watch: {
    open: function (val) {
      val && this.claimCheck()
      this.id = null
      this.relation = null
      if (!val) {
        this.reset()
        this.$refs.twoFactor && this.$refs.twoFactor.reset()
      }
    },
    'user.id': 'claimCheck'
  },
  components: {
    ContactAdd,
    TwoFactorAuth
  },
  mounted () {
    if (this.$route.query.claim === 'true') {
      this.open = true
      // this.$router.replace({ query: null })
    }
  }
}
</script>

<style scoped>

</style>
